import http from "@/libs/http";
// import ui from '@/libs/ui'

export function getTop(params) {
    return http.get('notice/getTop', params);
}

export function getList(params) {
    return http.get('notice/getList', {params: params});
}

export function getInfoData(params) {
    return http.get('notice/getInfo', {params: params});
}