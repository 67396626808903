<!--首页-->
<template>
  <div class="container home-box">
    <div class="row home-content">
      <div class="col-md-12">
        <div class="title-box">
          <p class="yw">数据库更新</p>
          <p class="wz">- Database Update - </p>
        </div>
        <div class="row">
          <div class="col-sm-4" v-for="info in updateInfo" :key="info.id">
            <el-card shadow="hover" class="card-db">
              <div class="img"><img :src="info.imgUrl" alt=""></div>
              <div class="card-content">
                <h5>{{ info.noticeTitle }}</h5>
                <span>更新时间: {{ info.updateDate }}</span>
                <div class="html" v-html="info.noticeContent"></div>
                <el-button type="primary" size="mini" @click="toDetail(info.noticeId)">查看详情 →</el-button>
              </div>
            </el-card>
          </div>
        </div>
        <el-button class="more" @click="goNotice(2)" round>
          <span class="text">查看更多<img src="../assets/img/select.png" alt=""></span>
        </el-button>
      </div>
      <div class="col-md-12 divide"></div>
      <div class="col-md-12">
        <div class="title-box">
          <p class="yw">法规与标准</p>
          <p class="wz">- Regulations And Standards -</p>
        </div>
        <div class="row">
          <div class="col-md-6" v-for="info in newsInfo" :key="info.id">
            <el-card shadow="hover" class="card-law">
              <div class="img"><img :src="info.imgUrl" alt=""></div>
              <div class="card-content">
                <h4>{{ info.noticeTitle }}</h4>
                <div class="html" v-html="info.noticeContent"></div>
                <el-button type="primary" size="mini" @click="toDetail(info.noticeId)">查看详情 →</el-button>
              </div>
            </el-card>
          </div>
        </div>
        <el-button class="more" @click="goNotice(1)" round>
          <span class="text">查看更多<img src="../assets/img/select.png" alt=""></span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getTop} from "@/api/notice";
import {getInstructions} from "@/api/common";
import update1 from '@/assets/img/update-1.jpg'
import update2 from '@/assets/img/update-2.jpg'
import update3 from '@/assets/img/update-3.jpg'
import law1 from '@/assets/img/law-1.png'
import law2 from '@/assets/img/law-2.jpg'

export default {
  name: 'Index',
  props: {
    msg: String
  },
  data() {
    return {
      updateInfo: [],
      newsInfo: []
    }
  },
  mounted() {
    getTop().then(data => {
      let index = 1, newsIndex = 1
      data.forEach((notice) => {
        if (notice.noticeType === '1'
            && this.newsInfo.length < 2) {
          if (newsIndex === 1) {
            notice.imgUrl = law1
          } else if (newsIndex === 2) {
            notice.imgUrl = law2
          }

          this.newsInfo.push(notice)
          newsIndex++
        } else {
          if (index === 1) {
            notice.imgUrl = update1
          } else if (index === 2) {
            notice.imgUrl = update2
          } else {
            notice.imgUrl = update3
          }

          this.updateInfo.push(notice)
          index++
        }
      })
    })

    this.getInstructions()
  },
  methods: {
    toDetail: function (id) {
      if (id == null) {
        return
      }
      this.$router.push('noticeDetail/' + id)
    },
    goNotice: function (type) {
      this.$router.push('/notice/' + type)
    },
    getInstructions: function () {
      getInstructions().then(data => {
        window.localStorage.setItem("instructions", JSON.stringify(data))
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../assets/less/home.less";
</style>
